import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { ScrollToTop } from "./components/Pages/ScrollToTop";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Network } from "@capacitor/network";
import AllEventsLoader from "./components/pagesLoaderComponent/allEventsLoader";
import MyProfileLoader from "./components/pagesLoaderComponent/myProfileLoader";
import CreateEventLoader from "./components/pagesLoaderComponent/createEventLoader";
import BuyLoader from "./components/pagesLoaderComponent/buyLoader";
import SellLoader from "./components/pagesLoaderComponent/sellLoader";
import EventDashboardLoader from "./components/pagesLoaderComponent/EventDashboardLoader";
import BuyerDashboardLoader from "./components/pagesLoaderComponent/buyerDashboardLoader";
import EventDetailsLoader from "./components/pagesLoaderComponent/eventDetailsLoader";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import ReactGA from "react-ga4";
import useDeviceDetails from "./components/CommonFunctions/getDeviceDetails";
import ClaimSubmission from "./components/EventsComponents/ClaimSubmission";
import LaunchSoon from "./components/Pages/LaunchSoonPage";

ReactGA.initialize([
  {
    trackingId: "G-WE1CT0X28Q",
  },
  {
    trackingId: "G-QJL3PLLE04",
  },
]);
// import EventDashboard from "./components/events/dashboard/seller/EventDashboard"
const Login = React.lazy(() =>
  import(/*webpackChunkName:"Login"*/ "./components/Authentications/login")
);

const AcceptInvitation = React.lazy(() =>
  import(/*webpackChunkName:"Login"*/ "./components/EventsComponents/AcceptAuctionInvitation")
);

const Disclaimer = React.lazy(() =>
  import(/*webpackChunkName:"Login"*/ "./components/Pages/Disclaimer")
);

const AcceptPrivateSaleInvitation = React.lazy(() =>
  import(/*webpackChunkName:"Login"*/ "./components/EventsComponents/AcceptPrivateSaleInvitation")
);

const Header = React.lazy(() =>
  import(/*webpackChunkName:"Header"*/ "./components/CommonComponents/header")
);

const Footer = React.lazy(() =>
  import(/*webpackChunkName:"Login"*/ "./components/CommonComponents/footer")
);

const Signup = React.lazy(() =>
  import(/*webpackChunkName:"Signup"*/ "./components/Authentications/signUp")
);
const ForgotPassword = React.lazy(() =>
  import(
    /*webpackChunkName:"ForgotPassword"*/ "./components/Authentications/forgotPassword"
  )
);
const EnterOtp = React.lazy(() =>
  import(/*webpackChunkName:"EnterOtp"*/ "./components/Authentications/enterOtp")
);
const CreateEvent = React.lazy(() =>
  import(/*webpackChunkName:"CreateEvent"*/ "./components/EventsComponents/CreateEvent")
);
const AllEvents = React.lazy(() =>
  import(/*webpackChunkName:"AllEvents"*/ "./components/EventsComponents/AllEvents")
);
const UserPreferences = React.lazy(() =>
  import(/*webpackChunkName:"UserPreferences"*/ "./components/Pages/UserPreferences")
);
const EventDetails = React.lazy(() =>
  import(/*webpackChunkName:"EventDetails"*/ "./components/EventsComponents/EventDetails")
);
const SharedEventDetails = React.lazy(() =>
  import(/*webpackChunkName:"EventDetails"*/ "./components/EventsComponents/SharedEventDetails")
);
const EventDashboard = React.lazy(() =>
  import(
    /*webpackChunkName:"EventDashboard"*/
    "./components/EventsComponents/EventDashboardComponents/EventDashboard"
  )
);
const BuyerDashboard = React.lazy(() =>
  import(
    /*webpackChunkName:"BuyerDashboard"*/
    "./components/EventsComponents/BuildingBlocksComponents/buyer/buyerDashboard"
  )
);
const MyProfile = React.lazy(() =>
  import(
    /*webpackChunkName:"MyProfile"*/
    "./components/UserprofilePages/MyProfile"
  )
);
const ContactUs = React.lazy(() =>
  import(/*webpackChunkName:"ContactUs"*/ "./components/Pages/ContactUs")
);
const AllBlogs = React.lazy(() =>
  import(/*webpackChunkName:"AllBlogs"*/ "./components/Pages/AllBlogs")
);
const Service = React.lazy(() =>
  import(/*webpackChunkName:"Service"*/ "./components/ServicesComponents/Service")
);
const ServiceStatus = React.lazy(() =>
  import(
    /*webpackChunkName:"ServiceStatus"*/
    "./components/ServicesComponents/ServiceStatus"
  )
);

const Faq = React.lazy(() =>
  import(/*webpackChunkName:"Faq"*/ "./components/Pages/Faq")
);
const Privacy = React.lazy(() =>
  import(/*webpackChunkName:"Faq"*/ "./components/Pages/Privacy")
);
const TermsOfServices = React.lazy(() =>
  import(/*webpackChunkName:"Faq"*/ "./components/Pages/TermsOfServices")
);

const Buy = React.lazy(() =>
  import(/*webpackChunkName:"Buy"*/ "./components/Pages/Buy")
);
const Sell = React.lazy(() =>
  import(/*webpackChunkName:"Sell"*/ "./components/Pages/Sell")
);

const LandingPage = React.lazy(() =>
  import(/*webpackChunkName:"LandingPage"*/ "./components/Pages/LandingPage")
);

const PriceEstimator = React.lazy(() =>
  import(/*webpackChunkName:"LandingPage"*/ "./components/ChatbotComponents/price_estimator")
);

const NotFound = React.lazy(() =>
  import(/*webpackChunkName:"LandingPage"*/ "./components/Pages/NoDataFound")
);

function App() {
  // console.log('process.env.NODE_ENV', window.location.pathname);
  // const location = useLocation()
  useDeviceDetails();
  useEffect(() => {
    //
    Network.addListener("networkStatusChange", (status) => {
      if (status.connected) {
        toast.success(`${"Your internet connection restored"}`, {
          theme: "light",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.error(`${"You have lost internet connection"}`, {
          theme: "light",
        });
      }
    });
  }, []);


  return (
    <BrowserRouter>
      <div className="wrapper position-relative">
        <Helmet
          defaultTitle="bidboli | The Asset Bazaar"
          titleTemplate="The Asset Bazaar | %s"
        >
          <script
            src="https://kit.fontawesome.com/b962d8bbf0.js"
            crossOrigin="anonymous"
          ></script>
        </Helmet>
         <LaunchSoon/>
        {/* <Header />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <Suspense
                fallback={
                  <div>
                    <img
                      height={"100%"}
                      alt="loader"
                      width={"100%"}
                      src="/assets/images/loaderGif.gif"
                    />
                  </div>
                }
              >
                <LandingPage />
              </Suspense>
            }
          />
           <Route
            path="/disclaimer"
            element={
              <Suspense
                fallback={
                  <div>
                    <img
                      height={"100%"}
                      alt="loader"
                      width={"100%"}
                      src="/assets/images/loaderGif.gif"
                    />
                  </div>
                }
              >
                <Disclaimer />
              </Suspense>
            }
          />
           <Route
            path="*"
            element={
              <Suspense
                fallback={
                  <div>
                    <img
                      height={"100%"}
                      alt="loader"
                      width={"100%"}
                      src="/assets/images/loaderGif.gif"
                    />
                  </div>
                }
              >
                <NotFound />
              </Suspense>
            }
          />
          <Route
            path="/login"
            element={
              <Suspense
                fallback={
                  <div>
                    <img
                      height={"100%"}
                      alt="loader"
                      width={"100%"}
                      src="/assets/images/loaderGif.gif"
                    />
                  </div>
                }
              >
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/sign_up"
            element={
              <Suspense
                fallback={
                  <div>
                    <img
                      height={"100%"}
                      alt="loader"
                      width={"100%"}
                      src="/assets/images/loaderGif.gif"
                    />
                  </div>
                }
              >
                <Signup />
              </Suspense>
            }
          />
          <Route
            path="/forgot_password"
            element={
              <Suspense
                fallback={
                  <div>
                    <img
                      height={"100%"}
                      alt="loader"
                      width={"100%"}
                      src="/assets/images/loaderGif.gif"
                    />
                  </div>
                }
              >
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path="/enter_otp"
            element={
              <Suspense
                fallback={
                  <div>
                    <img
                      height={"100%"}
                      alt="loader"
                      width={"100%"}
                      src="/assets/images/loaderGif.gif"
                    />
                  </div>
                }
              >
                <EnterOtp />
              </Suspense>
            }
          />
           <Route
            path="/price_estimator"
            element={
              <Suspense fallback={<SellLoader />}>
                <PriceEstimator />
              </Suspense>
            }
          />
          <Route
            path="/service"
            element={
              <Suspense fallback={<CreateEventLoader />}>
                <Service />
              </Suspense>
            }
          />
          <Route
            path="/service_status"
            element={
              <Suspense fallback={<CreateEventLoader />}>
                <ServiceStatus />
              </Suspense>
            }
          />
          <Route
            path="/create_event"
            element={
              <Suspense fallback={<CreateEventLoader />}>
                <CreateEvent />
              </Suspense>
            }
          />
          <Route
            path="/events"
            element={
              <Suspense fallback={<AllEventsLoader />}>
                <AllEvents />
              </Suspense>
            }
          />
          <Route
            path="/preferences"
            element={
              <Suspense fallback={<CreateEventLoader />}>
                <UserPreferences />
              </Suspense>
            }
          />
          <Route
            path="/event_details/:id"
            element={
              <Suspense fallback={<EventDetailsLoader />}>
                <EventDetails />
              </Suspense>
            }
          />
           <Route
            path="/auction_invitation_accepted/:event_id/:auction_id/:user_id/:auction_name"
            element={
              <Suspense fallback={<CreateEventLoader />}>
                <AcceptInvitation />
              </Suspense>
            }
          />
          <Route
            path="/privatesale_invitation_accepted/:event_id/:process_id/:user_id/:name"
            element={
              <Suspense fallback={<CreateEventLoader />}>
                <AcceptPrivateSaleInvitation />
              </Suspense>
            }
          />
          <Route
            path="/event/:id"
            element={
              <Suspense fallback={<EventDetailsLoader />}>
                <SharedEventDetails />
              </Suspense>
            }
          />
          <Route
            path="/claim_submission/:id"
            element={
              <Suspense fallback={<EventDetailsLoader />}>
                <ClaimSubmission />
              </Suspense>
            }
          />
          <Route
            path="/event_dashboard/:id"
            element={
              <Suspense fallback={<EventDashboardLoader />}>
                <EventDashboard />
              </Suspense>
            }
          />
          <Route
            path="/buyer_dashboard/:id"
            element={
              <Suspense fallback={<BuyerDashboardLoader />}>
                <BuyerDashboard />
              </Suspense>
            }
          />
          <Route
            path="/my_profile"
            element={
              <Suspense fallback={<MyProfileLoader />}>
                <MyProfile />
              </Suspense>
            }
          />
          <Route
            path="/contactus"
            element={
              <Suspense
                fallback={
                  <div>
                    <img
                      height={"100%"}
                      alt="loader"
                      width={"100%"}
                      src="/assets/images/loaderGif.gif"
                    />
                  </div>
                }
              >
                <ContactUs />
              </Suspense>
            }
          />
          <Route
            path="/faq"
            element={
              <Suspense
                fallback={
                  <div>
                    <img
                      height={"100%"}
                      alt="loader"
                      width={"100%"}
                      src="/assets/images/loaderGif.gif"
                    />
                  </div>
                }
              >
                <Faq />
              </Suspense>
            }
          />
          <Route
            path="/privacy"
            element={
              <Suspense
                fallback={
                  <div>
                    <img
                      height={"100%"}
                      alt="loader"
                      width={"100%"}
                      src="/assets/images/loaderGif.gif"
                    />
                  </div>
                }
              >
                <Privacy />
              </Suspense>
            }
          />
          <Route
            path="/termsOfServices"
            element={
              <Suspense
                fallback={
                  <div>
                    <img
                      height={"100%"}
                      alt="loader"
                      width={"100%"}
                      src="/assets/images/loaderGif.gif"
                    />
                  </div>
                }
              >
                <TermsOfServices />
              </Suspense>
            }
          />

          <Route
            path="/all_blogs/:id"
            element={
              <Suspense
                fallback={
                  <div>
                    <img
                      height={"100%"}
                      alt="loader"
                      width={"100%"}
                      src="/assets/images/loaderGif.gif"
                    />
                  </div>
                }
              >
                <AllBlogs />
              </Suspense>
            }
          />
          <Route
            path="/buy"
            element={
              <Suspense
                fallback={
                  <div>
                    <BuyLoader />
                  </div>
                }
              >
                <Buy />
              </Suspense>
            }
          />
          <Route
            path="/sell"
            element={
              <Suspense
                fallback={
                  <div>
                    <SellLoader />
                  </div>
                }
              >
                <Sell />
              </Suspense>
            }
          />
          <Route path="/linkedin" component={LinkedInCallback} />
        </Routes>
        <Footer /> */}
      </div>
    </BrowserRouter>
  );
}

export default App;
