"use client";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Styles/style.css";
import "./Styles/common.css";
import "./Styles/media.css";
import "./Styles/block-loader.css";
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId="921308227521-gs83s39mv9b912rgi737o57d5sq7hbue.apps.googleusercontent.com">
    {/* <React.StrictMode> */}
    <ToastContainer />
    {/* <ErrorBoundary
      fallbackRender={ErrorScreen}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}
    > */}
      <App />
    {/* </ErrorBoundary> */}
    {/* </React.StrictMode> */}
  </GoogleOAuthProvider>
);
