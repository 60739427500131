import React, { useState, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import { APIBASEURL } from "../../config/config_api";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

function LaunchSoon() {
    const [email, setEmail] = useState('');
    const [dataError, setDataError] = useState();
    const [loader, setLoader] = useState(false);
    const [status, setStatus] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const validator = new SimpleReactValidator({ autoForceUpdate: this });

    const handleChange = (e) => {
        setEmail(e.target.value)
    }

    const SubmitHandler = () => {
        validator.showMessages();
        const errorMessage = validator.getErrorMessages();
        setDataError(errorMessage);
        if (validator.allValid()) {
            setLoader(true);
            axios({
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
                url: APIBASEURL + "home/newsletterSignUp",
                data: {
                    email: email,
                },
            })
                .then((response) => {
                    setLoader(false);
                    if (response?.data?.status === true) {
                        setStatus(true);
                    } else {
                        if (response?.data?.tokenExpired === true) {
                            localStorage.clear();
                            navigate("/login");
                            localStorage.setItem("history", location.pathname);
                        }
                        toast.error(`${response?.data?.msg}`, {
                            theme: "light",
                        });
                    }
                })
                .catch((error) => {
                    toast.error(`${error}`, {
                        theme: "light",
                    });
                    setLoader(false);
                });
        }
    }

    const resetStatus = () => {
        setStatus(false)
        setDataError({})
        setEmail('')
    }

    return (
        <div className="home-container-main">
            {!status ?
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-8 col-lg-6">
                            <div className="email-notify-box-wrapper">
                                <h2>Launching Soon</h2>
                                <h4>India’s First Corporate Asset Exchange</h4>
                                <p>Register your email address to get an invitation to our launch party</p>
                                <div className="email-notify-box">
                                    <i class="fa fa-envelope" aria-hidden="true"></i>
                                    <input onChange={handleChange} value={email} type="text" placeholder="Email" />
                                    <button onClick={SubmitHandler}>
                                        {(loader === true) && (
                                            <div className="spinner-grow spinner-grow-sm text-light me-2" role="status"></div>
                                        )}
                                        {(loader === true) ? "Please wait..." : "Notify Me"}
                                    </button>
                                </div>
                                {validator.message("email", email, "required")}
                                {dataError?.email && (<p className="error-msg fs-13 pt-1">{dataError?.email}</p>)}
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="container">
                    <div className="statusResponse">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-10 m-auto text-center">
                                <h4>THANK YOU!</h4>
                                <p>Your request has been submitted and an email confirmation has been sent to your registered email address- {email}.</p>
                                <button onClick={resetStatus}>Go back</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default LaunchSoon;