let api_url;

if (process.env.REACT_APP_ENV === "development") {
   // for development server
   api_url = "https://fa7rdkjg5f.execute-api.us-east-1.amazonaws.com/bidboliv12/api/v1/";
      // api_url = "http://localhost:8000/api/v1/";
} else {
   // for production server
   api_url = "https://ezcnakt7hg.execute-api.us-east-1.amazonaws.com/prod/api/v1/";

}

export const APIBASEURL = api_url; 