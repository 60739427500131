import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { userDeviceDetails } from "../CommonComponents/userLoggindInfo";
import { APIBASEURL } from "../../config/config_api";
import axios from "axios";
import { toast } from "react-toastify";
import ReactFlagsSelect from "react-flags-select";
const phoneCodes = require("../../config/phoneCodes.json");

export default function ClaimSubmission() {
    const navigate = useNavigate();
    const location = useLocation();
    const event_id = location?.state?.event_id;
    const event_name = location?.state?.event_name;
    const event_bio = location?.state?.event_bio;
    const deviceDetails = userDeviceDetails();

    const InitialData = {
        event_id: event_id,
        class_of_creditor: "",
        is_applicant: false,
        best_estimate: false,
        releted_party: false,
        is_secured: false,
        security_interest: null,
        currency: "",
        amount_claimed: null,
        amount_admitted: null,
        amount_under_verification: null,
        amount_rejected: null,
        stakeholder_name: "",
        country_code: "IN",
        stakeholder_country_code: "+91",
        stakeholder_contact: "",
        stakeholder_class: "",
        is_releted_party_class: false,
        admitted_claim: null,
        transfer_document: "",
    };
    const [createClaimsCreditorData, setCreateClaimsCreditorData] = useState(InitialData);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [currencyList, setCurrencyList] = useState([]);

    useEffect(() => {
        getCurrencyList();
    }, []);

    const getCurrencyList = () => {
        let token = localStorage.getItem("token");
        axios({
            method: "get",
            url: APIBASEURL + "eventMaster/getCountries",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                device_details: JSON.stringify(deviceDetails),
                bb_id: 5,
            },
        })
            .then((response) => {
                setCurrencyList(response?.data?.data);
            })
            .catch((error) => { });
    };

    const handleChange = (e) => {
        const { value, checked, name } = e.target;
        if (name === "class_of_creditor") {
            setCreateClaimsCreditorData((prev) => ({
                ...prev,
                class_of_creditor: value,
            }));
        } else if (name === "amount_claimed") {
            setCreateClaimsCreditorData((prev) => ({
                ...prev,
                amount_claimed: value,
            }));
        } else if (name === "amount_admitted") {
            setCreateClaimsCreditorData((prev) => ({
                ...prev,
                amount_admitted: value,
            }));
        } else if (name === "amount_under_verification") {
            setCreateClaimsCreditorData((prev) => ({
                ...prev,
                amount_under_verification: value,
            }));
        } else if (name === "amount_rejected") {
            setCreateClaimsCreditorData((prev) => ({
                ...prev,
                amount_rejected: value,
            }));
        } else if (name === "stakeholder_name") {
            setCreateClaimsCreditorData((prev) => ({
                ...prev,
                stakeholder_name: value,
            }));
        } else if (name === "stakeholder_contact") {
            setCreateClaimsCreditorData((prev) => ({
                ...prev,
                stakeholder_contact: value,
            }));
        } else if (name === "stakeholder_class") {
            setCreateClaimsCreditorData((prev) => ({
                ...prev,
                stakeholder_class: value,
            }));
        } else if (name === "admitted_claim") {
            setCreateClaimsCreditorData((prev) => ({
                ...prev,
                admitted_claim: value,
            }));
        } else if (name === "is_releted_party_class") {
            setCreateClaimsCreditorData((prev) => ({
                ...prev,
                is_releted_party_class: value === "Yes" ? true : false,
            }));
        } else if (name === "is_applicant") {
            setCreateClaimsCreditorData((prev) => ({
                ...prev,
                is_applicant: value === "Yes" ? true : false,
            }));
        } else if (name === "is_secured") {
            setCreateClaimsCreditorData((prev) => ({
                ...prev,
                is_secured: value === "Yes" ? true : false,
            }));
        } else if (name === "best_estimate") {
            setCreateClaimsCreditorData((prev) => ({
                ...prev,
                best_estimate: checked,
            }));
        } else if (name === "currency") {
            setCreateClaimsCreditorData((prev) => ({ ...prev, currency: value }));
        }
    };

    const handleClaimsCreditor = (e) => {
        setUpdateLoader(true);
        const token = localStorage.getItem("token");
        axios({
            method: "post",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                device_details: JSON.stringify(deviceDetails),
                bb_id: 5,
            },
            url: APIBASEURL + "bb/addClaimsAndCreditors",
            data: {
                event_id: event_id,
                class_of_creditor: createClaimsCreditorData?.class_of_creditor,
                is_applicant: createClaimsCreditorData?.is_applicant,
                best_estimate: createClaimsCreditorData?.best_estimate,
                releted_party: createClaimsCreditorData?.releted_party,
                is_secured: createClaimsCreditorData?.is_secured,
                currency: createClaimsCreditorData?.currency,
                amount_claimed: createClaimsCreditorData?.amount_claimed,
                amount_admitted: createClaimsCreditorData?.amount_admitted,
                amount_under_verification: createClaimsCreditorData?.amount_under_verification,
                amount_rejected: createClaimsCreditorData?.amount_rejected,
                stakeholder_name: createClaimsCreditorData?.stakeholder_name,
                stakeholder_country_code: createClaimsCreditorData?.stakeholder_country_code,
                stakeholder_contact: createClaimsCreditorData?.stakeholder_contact,
                stakeholder_class: createClaimsCreditorData?.stakeholder_class,
                is_releted_party_class: createClaimsCreditorData?.is_releted_party_class,
                admitted_claim: createClaimsCreditorData?.admitted_claim,
            },
        })
            .then((response) => {
                setUpdateLoader(false);
                if (response?.data?.status === true) {
                    toast.success(`${response?.data?.msg}`, {
                        theme: "light",
                    });
                    navigate(`/event_details/${event_id}`);
                } else {
                    toast.error(`${response?.data?.msg}`, {
                        theme: "light",
                    });
                }
            })
            .catch((error) => {
                setUpdateLoader(false);
                toast.error(`${error}`, {
                    theme: "light",
                });
            });
    };

    const gotoEventDetail = () => {
        navigate(`/event_details/${event_id}`);
    }

    return (
        <div className="bg-grey-lite">
            <Helmet>
                <title>Submit Claim</title>
            </Helmet>
            <div className="container">
                <h2 className="m-0 text-center fw-bold py-4">Submit Claim</h2>
                <div className="row">
                    <div className="col-md-10 m-auto">
                        <div className="fancy-card bg-lite pb-5 mb-5 position-relative">
                            <div className="row m-0 py-5" style={{ borderRadius: "15px 15px 0px 0px", background: "#484848" }}>
                                <div className="col-10 p-0 m-auto text-white">
                                    <p className="mb-1">{event_name}</p>
                                    <h5 className="m-0 fw-bold">{event_bio}</h5>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-sm-10 m-auto">
                                    <div className="row">
                                        <div className="col-8">
                                            <h4 className="fw-bold mt-3 m-0">Please fill the details below to Submit a claim</h4>
                                            <hr />
                                            <div className="form-data my-3">
                                                <h3 className="fw-bold h5 mt-5 mb-3">Claims Submitted:</h3>
                                                <div className="row">
                                                    <div className="col-md-6 capsule-filters my-1">
                                                        <input
                                                            type="radio"
                                                            className="btn_radio"
                                                            onChange={handleChange}
                                                            name="class_of_creditor"
                                                            value="OC"
                                                            id="OC"
                                                            checked={
                                                                createClaimsCreditorData?.class_of_creditor === "OC"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        <label
                                                            className="h6 m-0 text-center"
                                                            htmlFor="OC"
                                                            style={{
                                                                padding: "10px",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            OC
                                                        </label>
                                                        {createClaimsCreditorData?.class_of_creditor === "OC" && (
                                                            <div className="row my-2">
                                                                <div className="col-6">
                                                                    <p className="text-secondary m-xl-0 h6 fw-bold">
                                                                        is Applicant?
                                                                    </p>
                                                                </div>
                                                                <div className="col-3">
                                                                    <input
                                                                        className="form-check-input me-2"
                                                                        type="radio"
                                                                        name="is_applicant"
                                                                        onChange={handleChange}
                                                                        value="Yes"
                                                                        id="Yes"
                                                                        checked={
                                                                            createClaimsCreditorData?.is_applicant === true
                                                                        }
                                                                    />
                                                                    <label htmlFor="Yes" className="sm_tex fs-6">
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="col-3">
                                                                    <input
                                                                        className="form-check-input me-2"
                                                                        name="is_applicant"
                                                                        onChange={handleChange}
                                                                        value="No"
                                                                        id="No"
                                                                        type="radio"
                                                                        checked={
                                                                            createClaimsCreditorData?.is_applicant === false
                                                                        }
                                                                    />
                                                                    <label htmlFor="No" className="sm_tex fs-6">
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 capsule-filters my-1">
                                                        <input
                                                            type="radio"
                                                            className="btn_radio"
                                                            name="class_of_creditor"
                                                            onChange={handleChange}
                                                            value="FC"
                                                            id="FC"
                                                            checked={
                                                                createClaimsCreditorData?.class_of_creditor === "FC"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        <label
                                                            className="h6 m-0 text-center"
                                                            htmlFor="FC"
                                                            style={{
                                                                padding: "10px",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            FC
                                                        </label>
                                                        {createClaimsCreditorData?.class_of_creditor === "FC" && (
                                                            <div className="row my-2">
                                                                <div className="col-6">
                                                                    <p className="text-secondary m-xl-0 h6 fw-bold">
                                                                        is Applicant?
                                                                    </p>
                                                                </div>
                                                                <div className="col-3">
                                                                    <input
                                                                        className="form-check-input me-2"
                                                                        type="radio"
                                                                        name="is_applicant"
                                                                        onChange={handleChange}
                                                                        value="Yes"
                                                                        id="Yes"
                                                                        checked={
                                                                            createClaimsCreditorData?.is_applicant === true
                                                                        }
                                                                    />
                                                                    <label htmlFor="Yes" className="sm_tex fs-6">
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="col-3">
                                                                    <input
                                                                        className="form-check-input me-2"
                                                                        name="is_applicant"
                                                                        onChange={handleChange}
                                                                        value="No"
                                                                        id="No"
                                                                        type="radio"
                                                                        checked={
                                                                            createClaimsCreditorData?.is_applicant === false
                                                                        }
                                                                    />
                                                                    <label htmlFor="No" className="sm_tex fs-6">
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-6 capsule-filters my-1">
                                                        <input
                                                            type="radio"
                                                            className="btn_radio"
                                                            name="class_of_creditor"
                                                            onChange={handleChange}
                                                            value="Workmen And Employees"
                                                            id="Workmen And Employees"
                                                            checked={
                                                                createClaimsCreditorData?.class_of_creditor ===
                                                                    "Workmen And Employees"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        <label
                                                            className="h6 m-0 text-center"
                                                            htmlFor="Workmen And Employees"
                                                            style={{
                                                                padding: "10px",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            Workmen And Employees
                                                        </label>
                                                    </div>
                                                    <div className="col-md-6 capsule-filters my-1">
                                                        <input
                                                            type="radio"
                                                            className="btn_radio"
                                                            name="class_of_creditor"
                                                            onChange={handleChange}
                                                            value="Other Stakeholders"
                                                            id="Other Stakeholders"
                                                            checked={
                                                                createClaimsCreditorData?.class_of_creditor ===
                                                                    "Other Stakeholders"
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                        <label
                                                            className="h6 m-0 text-center"
                                                            htmlFor="Other Stakeholders"
                                                            style={{
                                                                padding: "10px",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            Other Stakeholders
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-data mt-5 mb-3">
                                                <h3 className="fw-bold h5 mb-3">Claims Admitted:</h3>
                                                <div className="row mb-3">
                                                    <div className="col-8">
                                                        <p className="text-secondary m-xl-0 h6 fw-bold">
                                                            Claims Admitted can be a best estimate (Instead of
                                                            Precise)
                                                        </p>
                                                    </div>
                                                    <div className="col-4">
                                                        <input
                                                            className="form-check-input me-2"
                                                            type="checkbox"
                                                            name="best_estimate"
                                                            onChange={handleChange}
                                                            id="Best Estimate"
                                                            checked={createClaimsCreditorData?.best_estimate === true}
                                                        />
                                                        <label htmlFor="Best Estimate" className="sm_tex fs-6">
                                                            Best Estimate
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-8">
                                                        <p className="text-secondary m-xl-0 h6 fw-bold">
                                                            Security Interest Created
                                                        </p>
                                                    </div>
                                                    <div className="col-4">
                                                        <input
                                                            className="form-check-input me-2"
                                                            type="radio"
                                                            name="is_secured"
                                                            value="Yes"
                                                            onChange={handleChange}
                                                            id="Yes"
                                                            checked={createClaimsCreditorData?.is_secured === true}
                                                        />
                                                        <label htmlFor="Yes" className="sm_tex fs-6">
                                                            Yes
                                                        </label>
                                                        <input
                                                            className="form-check-input ms-4 me-2"
                                                            type="radio"
                                                            name="is_secured"
                                                            value="No"
                                                            onChange={handleChange}
                                                            id="No"
                                                            checked={createClaimsCreditorData?.is_secured === false}
                                                        />
                                                        <label htmlFor="No" className="sm_tex fs-6">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-data mb-3">
                                                <select
                                                    className="w-100 form-select create-event-form-input"
                                                    name="currency"
                                                    defaultValue={createClaimsCreditorData?.currency}
                                                    onChange={handleChange}
                                                >
                                                    <option className="h6" value="">
                                                        Currency Type
                                                    </option>
                                                    {currencyList?.map((data, index) => (
                                                        <option key={index} className="h6" value={data?.currency}>
                                                            {data?.currency} ({data?.name})
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="form-data mb-3">
                                                <input
                                                    type="number"
                                                    name="amount_claimed"
                                                    onChange={handleChange}
                                                    value={createClaimsCreditorData?.amount_claimed}
                                                    className="form-control create-event-form-input"
                                                    placeholder="Claimed Amount"
                                                />
                                            </div>
                                            <div className="form-data mb-3">
                                                <input
                                                    type="number"
                                                    name="amount_admitted"
                                                    onChange={handleChange}
                                                    value={createClaimsCreditorData?.amount_admitted}
                                                    className="form-control create-event-form-input"
                                                    placeholder="Admitted Amount"
                                                />
                                            </div>
                                            <div className="form-data mb-3">
                                                <input
                                                    type="number"
                                                    name="amount_under_verification"
                                                    onChange={handleChange}
                                                    value={createClaimsCreditorData?.amount_under_verification}
                                                    className="form-control create-event-form-input"
                                                    placeholder="Under Verification Amount"
                                                />
                                            </div>
                                            <div className="form-data mb-3">
                                                <input
                                                    type="number"
                                                    name="amount_rejected"
                                                    onChange={handleChange}
                                                    value={createClaimsCreditorData?.amount_rejected}
                                                    className="form-control create-event-form-input"
                                                    placeholder="Rejected Amount"
                                                />
                                            </div>
                                            <div className="form-data mt-5 mb-3">
                                                <input
                                                    type="text"
                                                    name="stakeholder_name"
                                                    onChange={handleChange}
                                                    value={createClaimsCreditorData?.stakeholder_name}
                                                    className="form-control create-event-form-input"
                                                    placeholder="Name of Stakeholder"
                                                />
                                            </div>
                                            <div className="form-data d-flex mb-3">
                                                <ReactFlagsSelect
                                                    className="border-0 phonecode-dropdown"
                                                    autocomplete="false"
                                                    selected={createClaimsCreditorData?.country_code}
                                                    showOptionLabel={true}
                                                    searchable={true}
                                                    customLabels={phoneCodes}
                                                    showSelectedLabel={true}
                                                    onSelect={(code) => {
                                                        setCreateClaimsCreditorData((prev) => ({
                                                            ...prev,
                                                            country_code: code,
                                                        }));
                                                        for (const key in phoneCodes) {
                                                            if (code === key) {
                                                                setCreateClaimsCreditorData((prev) => ({
                                                                    ...prev,
                                                                    stakeholder_country_code: `${phoneCodes[key]}`,
                                                                }));
                                                            }
                                                        }
                                                    }}
                                                ></ReactFlagsSelect>
                                                <input
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={createClaimsCreditorData?.stakeholder_contact}
                                                    name="stakeholder_contact"
                                                    className="form-control create-event-form-input"
                                                    placeholder="Contact"
                                                />
                                            </div>
                                            <div className="form-data mb-3">
                                                <input
                                                    type="number"
                                                    onChange={handleChange}
                                                    value={createClaimsCreditorData?.admitted_claim}
                                                    name="admitted_claim"
                                                    className="form-control create-event-form-input"
                                                    placeholder="Admitted Claim"
                                                />
                                            </div>
                                            <div className="form-data mb-3 mt-5">
                                                <h3 className="fw-bold h5">
                                                    Class of Stakeholder: (Choose one)
                                                </h3>
                                            </div>
                                            <div className="form-data mb-3 capsule-filters my-1">
                                                <input
                                                    onChange={handleChange}
                                                    type="radio"
                                                    className="btn_radio"
                                                    name="stakeholder_class"
                                                    value="Secured Financial Creditors, who have relinquished their security interests under Section 52"
                                                    id="Secured Financial Creditors, who have relinquished their security interests under Section 52"
                                                    checked={
                                                        createClaimsCreditorData?.stakeholder_class ===
                                                            "Secured Financial Creditors, who have relinquished their security interests under Section 52"
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <label
                                                    className="h6 m-0 "
                                                    htmlFor="Secured Financial Creditors, who have relinquished their security interests under Section 52"
                                                    style={{
                                                        padding: "10px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    Secured Financial Creditors, who have relinquished their
                                                    security interests under Section 52
                                                </label>

                                                {createClaimsCreditorData?.stakeholder_class ===
                                                    "Secured Financial Creditors, who have relinquished their security interests under Section 52" && (
                                                        <div className="row my-2">
                                                            <div className="col-6">
                                                                <p className="text-secondary m-xl-0 h6 fw-bold">
                                                                    is Related Party?
                                                                </p>
                                                            </div>
                                                            <div className="col-3">
                                                                <input
                                                                    className="form-check-input me-2"
                                                                    type="radio"
                                                                    name="is_releted_party_class"
                                                                    onChange={handleChange}
                                                                    value="Yes"
                                                                    id="Yes"
                                                                    checked={
                                                                        createClaimsCreditorData?.is_releted_party_class ===
                                                                        true
                                                                    }
                                                                />
                                                                <label htmlFor="Yes" className="sm_tex fs-6">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="col-3">
                                                                <input
                                                                    className="form-check-input me-2"
                                                                    name="is_releted_party_class"
                                                                    onChange={handleChange}
                                                                    value="No"
                                                                    id="No"
                                                                    type="radio"
                                                                    checked={
                                                                        createClaimsCreditorData?.is_releted_party_class ===
                                                                        false
                                                                    }
                                                                />
                                                                <label htmlFor="No" className="sm_tex fs-6">
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="form-data mb-3 capsule-filters my-1">
                                                <input
                                                    type="radio"
                                                    className="btn_radio"
                                                    name="stakeholder_class"
                                                    value="Unsecured Financial Creditors"
                                                    onChange={handleChange}
                                                    id="Unsecured Financial Creditors"
                                                    checked={
                                                        createClaimsCreditorData?.stakeholder_class ===
                                                            "Unsecured Financial Creditors"
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <label
                                                    className="h6 m-0 "
                                                    htmlFor="Unsecured Financial Creditors"
                                                    style={{
                                                        padding: "10px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    Unsecured Financial Creditors
                                                </label>
                                                {createClaimsCreditorData?.stakeholder_class ===
                                                    "Unsecured Financial Creditors" && (
                                                        <div className="row my-2">
                                                            <div className="col-6">
                                                                <p className="text-secondary m-xl-0 h6 fw-bold">
                                                                    is Related Party?
                                                                </p>
                                                            </div>
                                                            <div className="col-3">
                                                                <input
                                                                    className="form-check-input me-2"
                                                                    type="radio"
                                                                    name="is_releted_party_class"
                                                                    onChange={handleChange}
                                                                    value="Yes"
                                                                    id="Yes"
                                                                    checked={
                                                                        createClaimsCreditorData?.is_releted_party_class ===
                                                                        true
                                                                    }
                                                                />
                                                                <label htmlFor="Yes" className="sm_tex fs-6">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="col-3">
                                                                <input
                                                                    className="form-check-input me-2"
                                                                    name="is_releted_party_class"
                                                                    onChange={handleChange}
                                                                    value="No"
                                                                    id="No"
                                                                    type="radio"
                                                                    checked={
                                                                        createClaimsCreditorData?.is_releted_party_class ===
                                                                        false
                                                                    }
                                                                />
                                                                <label htmlFor="No" className="sm_tex fs-6">
                                                                    No
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                            <div className="form-data mb-3 capsule-filters my-1">
                                                <input
                                                    type="radio"
                                                    className="btn_radio"
                                                    name="stakeholder_class"
                                                    value="Workmen and Employees"
                                                    onChange={handleChange}
                                                    id="Workmen and Employees"
                                                    checked={
                                                        createClaimsCreditorData?.stakeholder_class ===
                                                            "Workmen and Employees"
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <label
                                                    className="h6 m-0 "
                                                    htmlFor="Workmen and Employees"
                                                    style={{
                                                        padding: "10px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    Workmen and Employees
                                                </label>
                                            </div>
                                            <div className="form-data mb-3 capsule-filters my-1">
                                                <input
                                                    type="radio"
                                                    className="btn_radio"
                                                    name="stakeholder_class"
                                                    value="Governments"
                                                    onChange={handleChange}
                                                    id="Governments"
                                                    checked={
                                                        createClaimsCreditorData?.stakeholder_class ===
                                                            "Governments"
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <label
                                                    className="h6 m-0 "
                                                    htmlFor="Governments"
                                                    style={{
                                                        padding: "10px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    Governments
                                                </label>
                                            </div>
                                            <div className="form-data mb-3 capsule-filters my-1">
                                                <input
                                                    type="radio"
                                                    className="btn_radio"
                                                    name="stakeholder_class"
                                                    onChange={handleChange}
                                                    value="Operational Creditors other than Workmen, employees and Governments"
                                                    id="Operational Creditors other than Workmen, employees and Governments"
                                                    checked={
                                                        createClaimsCreditorData?.stakeholder_class ===
                                                            "Operational Creditors other than Workmen, employees and Governments"
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <label
                                                    className="h6 m-0 "
                                                    htmlFor="Operational Creditors other than Workmen, employees and Governments"
                                                    style={{
                                                        padding: "10px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    Operational Creditors other than Workmen, employees and
                                                    Governments
                                                </label>
                                            </div>
                                            <div className="form-data mb-3 capsule-filters my-1">
                                                <input
                                                    type="radio"
                                                    className="btn_radio"
                                                    name="stakeholder_class"
                                                    value="Shareholders or Partners, if applicable"
                                                    onChange={handleChange}
                                                    id="Shareholders or Partners, if applicable"
                                                    checked={
                                                        createClaimsCreditorData?.stakeholder_class ===
                                                            "Shareholders or Partners, if applicable"
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <label
                                                    className="h6 m-0 "
                                                    htmlFor="Shareholders or Partners, if applicable"
                                                    style={{
                                                        padding: "10px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    Shareholders or Partners, if applicable
                                                </label>
                                            </div>
                                            <div className="form-data mb-3 capsule-filters my-1">
                                                <input
                                                    type="radio"
                                                    className="btn_radio"
                                                    name="stakeholder_class"
                                                    value="promoters, directors, partners or their representatives (no right to vote)"
                                                    onChange={handleChange}
                                                    id="promoters, directors, partners or their representatives (no right to vote)"
                                                    checked={
                                                        createClaimsCreditorData?.stakeholder_class ===
                                                            "promoters, directors, partners or their representatives (no right to vote)"
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <label
                                                    className="h6 m-0 "
                                                    htmlFor="promoters, directors, partners or their representatives (no right to vote)"
                                                    style={{
                                                        padding: "10px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    promoters, directors, partners or their representatives (no
                                                    right to vote)
                                                </label>
                                            </div>
                                            <div className="form-data mb-3 capsule-filters my-1">
                                                <input
                                                    type="radio"
                                                    className="btn_radio"
                                                    name="stakeholder_class"
                                                    value="Representatives of any of the above"
                                                    onChange={handleChange}
                                                    id="Representatives of any of the above"
                                                    checked={
                                                        createClaimsCreditorData?.stakeholder_class ===
                                                            "Representatives of any of the above"
                                                            ? true
                                                            : false
                                                    }
                                                />
                                                <label
                                                    className="h6 m-0 "
                                                    htmlFor="Representatives of any of the above"
                                                    style={{
                                                        padding: "10px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    Representatives of any of the above
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="create-event-bottom-btns">
                                <div className="btn-cancel-event w-50" onClick={gotoEventDetail}>Cancel</div>
                                <div onClick={handleClaimsCreditor} className="btn-proceed-event w-50">
                                    {updateLoader && (
                                        <div className="spinner-grow spinner-grow-sm text-light me-3" role="status"></div>
                                    )}
                                    {updateLoader
                                        ? "Proceeding.."
                                        : "Proceed"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}
